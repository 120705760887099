import React from "react";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import NavBarHome from "../home/NavBarHome";
import DatePicker, { registerLocale } from "react-datepicker";
import { addDays } from "date-fns";
import { subDays } from "date-fns";
import { useDispatch } from "react-redux";
import { savedataStepZero } from "../souscription/store/actionSouscription";
import formuleService from "../../services/formule/formuleService";
import { toast } from "react-toastify";
import Footer from "../Footer/Footer";
import familyService from "../../services/family/familyService";
import fr from "date-fns/locale/fr";
import DocumentMeta from "react-document-meta";
import { saveFormule } from "../CreateUser/store/userAction";
import { Accordion } from "react-bootstrap";
import { Fragment } from "react";
import data from "../HomePageFooter/helpData.json";
import adminService from "../../services/adminService/adminService";
import {
  Button,
  Col,
  Input,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
} from "reactstrap";
import contractService from "../../services/contract/contractService";
registerLocale("fr", fr);
const Optiquedetails = () => {
  const meta = {
    title:
      "Assurance optique Tunisie : Garantie casse lunette, monture By Garanty",
    description:
      "Gamme complète de garantie optique par Garanty: Assurance optique pour lunette contre toute casse de monture et verre. Prise en charge frais de réparation ou remplacement",
    canonical: "http://example.com/path/to/page",
    meta: {
      charset: "utf-8",
      'http-equiv': {
        'cache-control': 'no-cache, must-revalidate, post-check=0, pre-check=0',
        expires: '0',
        pragma: 'no-cache',
      },
      name: {
        keywords: "react,meta,document,html,tags",
      },
    },
  };
  const location = useLocation();
  const dispatch = useDispatch();
  const user = JSON.parse(localStorage.getItem("user"));
  const history = useHistory();
  const [formuleList, setFormuleList] = useState([]);
  const [keyFormula, setKeyFormula] = useState(0);
  const [startDate, setStartDate] = useState(new Date());
  const [show, setShow] = useState(false);
  const [simulation, setSimulation] = useState([]);
  const [showData, setShowData] = useState(true);
  const [allData, setAllData] = useState(data);
  const [newsletter, setNewsletter] = useState({ email: "" });
  const [Verticalcenter, setVerticalcenter] = useState(false);
  const [contractInc, setContractInc] = useState(null);
  const Verticalcentermodaltoggle = () => {
    setVerticalcenter(!Verticalcenter);
  };
  const handelChange = (e) => {
    const { name, value } = e.target;
    setNewsletter((newsletter) => ({ ...newsletter, [name]: value }));
  };
  const subscribe = (index) => {
    dispatch(saveFormule(simulation[index]));
    if (localStorage.getItem("user")) {
      history.push({
        pathname: "/Souscription/Etape1",
      });
    } else {
      history.push({
        pathname: "/connexion",
      });
    }
  };
  const [formuleDto, setFormuleDto] = useState({
    amount: "",
    dateAchat: "",
    idFamille: location.state,
  });
  const [familyList, setFamilyList] = useState();
  useEffect(() => {
    familyService.getAllFamily().then((res) => {
      setFamilyList(res.data.sort((a, b) => a.displayOrder - b.displayOrder).filter((elem, i) => elem.displayOrder < 4 ));
    });
    formuleService.getAllFormulaById(location.state).then((res) => {
      setFormuleList(
        res?.data?.sort((a, b) => a?.fomula?.price - b?.fomula?.price)
      );
    });
    localStorage.setItem("idFamily", location.state);
  }, []);
  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormuleDto((formuleDto) => ({ ...formuleDto, [name]: value }));
  };

  const Continuer = async (e) => {
    e.preventDefault();
    if (formuleDto.amount && formuleDto.dateAchat) {
      dispatch(savedataStepZero(formuleDto));
      await formuleService
        .calculateFormula(formuleDto)
        .then(async (response) => {
          if (response.status === 200) {
            setSimulation(response.data);
            setShow(true);
            window.scrollTo(0, 900);
          } else {
            toast.error("Format incorrect du coût d'achat");
          }
        });
    } else if (!formuleDto.amount) {
      toast.error("Enter votre prix d'achat du produit");
    } else if (!formuleDto.dateAchat) {
      toast.error("Enter votre date d'achat du produit");
    }
  };
  const handelChangeSi = (e) => {
    const { name, value } = e.target;
    setContractInc((contractInc) => ({ ...contractInc, [name]: value }));
  };
  const validPopup = async () => {
    await contractService
      .getContractInclusion(
        contractInc.matricule,
        contractInc.seq_assu
      )
      .then((res) => {
        if(res.status === 200){
          history.push({
            pathname: "/inclusion/sinistre",
            state: res.data
          });
        }
      });
  };
  return (
    <DocumentMeta {...meta} className="container-fluid">
      <NavBarHome listFamily={familyList} />
      <div className="row col-md-12 header-container-slidee4"></div>
      <div
        className="row col-md-12 margin0 d-flex-j-c sumilateur"
        style={{ marginTop: "5%" }}
      >
        <div
          className="col-md-3 aide div-btn-calculate"
          style={{ marginLeft: "auto", marginBottom: "5%" }}
        >
          <button
            id="continuer"
            className="newletter-btn"
            onClick={Verticalcentermodaltoggle}
          >
            Déclaration Sinistre
          </button>
        </div>
        <div className="col-md-12 ">
          <h1 className="enfin-garantie" style={{ color: "#fc06e7" }}>
            Enfin une Garantie optique...
          </h1>
          <p className="simulez-prix1" style={{ color: "#fc06e7" }}>
            Simulez le prix de votre Garantie optique
          </p>
          <div className="row col-md-12 simulation-input">
            <div className="row col-md-8">
              <div className="col-md-4 aide">
                <input
                  name="amount"
                  type="number"
                  className="input-prix"
                  placeholder="Coût d'achat"
                  value={formuleDto.amount}
                  onChange={handleChange}
                  required
                />
                <div class="invalid-feedback">Please provide an amount.</div>
              </div>
              <div className="col-md-4 aide">
                <DatePicker
                  selected={startDate}
                  minDate={subDays(new Date(), 0)}
                  maxDate={addDays(new Date(), 0)}
                  dateFormat="dd/MM/yyyy"
                  className="input-prix"
                  placeholderText="Date d’achat"
                  value={formuleDto.dateAchat}
                  onChange={(date) => {
                    setStartDate(date);
                    handleChange({
                      target: { name: "dateAchat", value: date },
                    });
                  }}
                />
              </div>
              <div className="col-md-4 aide div-btn-calculate">
                <div className="calcule-btn">
                  <button
                    id="continuer"
                    className="calcule-btn"
                    onClick={Continuer}
                  >
                    Continuer
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="row col-lg-12 col-md-12 formule-offer"
        style={!show ? { display: "none" } : {}}
      >
        <div className="row col-md-8">
          <div
            className="col-md-12 text-center"
            style={{ marginBottom: "30px", marginTop: "50px" }}
          >
            <h2 className="simulez-prix">
              LA MEILLEURE PROTECTION POUR VOS LUNETTES
            </h2>
          </div>
          <div className="formula-bloc">
            {simulation.map((el, i) => (
              <div className="formule-bloc">
                <div className="div-extend-formule" style={{ height: "auto" }}>
                  <h3 className="text-align">Formule</h3>
                  <img
                    className="img-formule-extend"
                    style={
                      el?.fomula?.price === 11.5 ? { maxWidth: "312px" } : {}
                    }
                    src={
                      `${process.env.PUBLIC_URL}/assets/images/` +
                      el?.fomula?.name +
                      ".svg"
                    }
                  />
                  <div className="d-flex">
                    <p
                      style={{
                        fontSize: "28px",
                        fontWeight: "bold",
                        marginTop: "15%",
                        fontFamily: "Century Gothic",
                      }}
                    >
                      {(el?.fomula?.price / 12)?.toFixed(3)} DT / mois
                    </p>
                    <p
                      style={{
                        fontWeight: "bold",
                        fontSize: "20px",
                        marginBottom: "6px",
                      }}
                    >
                      {el?.fomula?.price?.toFixed(3)} DT / an
                    </p>
                    <p style={{ fontWeight: "bold", fontSize: "12px" }}>
                      prix des lunettes de {el?.fomula?.minValueProd}
                      {el?.fomula?.maxValueProd >= 9998
                        ? " et +"
                        : `${-el?.fomula?.maxValueProd} DT`}
                    </p>
                    <div className="row col-md-12 detail-formule">
                      <p className="col-md-12" style={{ fontSize: "14px" }}>
                        {el?.fomula?.maxValueProd <= 500 &&
                        el?.fomula?.minValueProd <= 200
                          ? "Lunettes de vue"
                          : "Lunettes de vue et solaires"}
                      </p>
                    </div>
                    <div className="row col-md-12 detail-formule">
                      <p className="col-md-12" style={{ fontSize: "14px" }}>
                        {el?.fomula?.maxValueProd < 500 &&
                        el?.fomula?.minValueProd === 1
                          ? "4 paires du foyer couvertes"
                          : "1 paire couverte"}
                      </p>
                    </div>
                    <div className="row col-md-12 detail-formule">
                      <p className="col-md-10" style={{ fontSize: "12px" }}>
                        {el?.fomula?.nbrIntervYear > 1
                          ? "4 interventions /an"
                          : "1 intervention /an"}
                      </p>
                    </div>
                    {el?.listBreakDown?.map((br, ke) =>
                      br.value ? (
                        <>
                          <div
                            key={ke}
                            className="row col-md-12 detail-formule"
                          >
                            <p
                              className="col-md-10"
                              style={{ fontSize: "12px" }}
                            >
                              {br.name}
                            </p>
                          </div>
                        </>
                      ) : (
                        <></>
                      )
                    )}
                    <div className="row col-md-12 detail-formule">
                      <p className="col-md-12" style={{ fontSize: "13px" }}>
                        Assistance téléphonique
                      </p>
                    </div>
                    <p
                      style={{
                        fontSize: "17px",
                        fontWeight: "bold",
                        marginTop: "5px",
                      }}
                    >
                      Plafond de garantie {el?.fomula?.ceilingGuarantee} DT
                    </p>
                    <div className="row col-md-12 detail-formule">
                      <p className="col-md-12" style={{ fontSize: "14px" }}>
                        Dont verre {el?.fomula?.dontverre} DT
                      </p>
                    </div>
                    <div className="row col-md-12 detail-formule">
                      <p className="col-md-12" style={{ fontSize: "14px" }}>
                        Dont montures {el?.fomula?.dontmontures} DT
                      </p>
                    </div>
                  </div>
                  <div
                    className="simulation-btn"
                    key={i}
                    onClick={() => subscribe(i)}
                    id="souscrire"
                    style={{ marginTop: "30px" }}
                  >
                    <p
                      style={{
                        font: "normal normal bold 14px/40px Century Gothic",
                        fontWeight: "bold",
                        color: "#FFFFFF",
                      }}
                    >
                      Souscrire
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* <div
        className="row col-md-12 specialiste-ass"
        style={{ paddingTop: "30px", marginTop: "5%" }}
      >
        <h2
          className="title-footer col-lg-9 col-md-10 col-sm-12 col-12"
          style={{ marginBottom: "10px" }}
        >
          COMMENT ÇA MARCHE?
        </h2>
        <div
          className="row col-md-10 col-sm-10"
          style={{
            display: "flex",
            justifyContent: "center",
            marginRight: "0",
            marginTop: "50px",
            marginBottom: "50px",
          }}
        >
          <div className="form3 bloc-sinistre-boostrap">
            <div className="bloc-marche">
              <div className="num-stepperrr">1</div>

              <p className="parg-marche">
                Simulez le prix de votre garantie en indiquant le coût et la
                date d'achat de votre produit.
              </p>
            </div>
            <div className="div-arrow">
              <img
                className="img-arrow"
                src={`${process.env.PUBLIC_URL}/assets/images/arrow.svg`}
              />
            </div>
          </div>
          <div className="form3 bloc-sinistre-boostrap">
            <div className="bloc-marche">
              <div className="num-stepperrr">2</div>

              <p className="parg-marche">
                Créez votre compte Garanty en 2 minutes, complétez vos
                informations personnelles puis validez.
              </p>
            </div>
            <div className="div-arrow">
              <img
                className="img-arrow"
                src={`${process.env.PUBLIC_URL}/assets/images/arrow.svg`}
              />
            </div>
          </div>
          <div className="form3 bloc-sinistre-boostrap">
            <div className="bloc-marche">
              <div className="num-stepperrr">3</div>
              <p className="parg-marche">
                Souscrivez en indiquant les informations personnelles. Ajoutez
                les documents demandés (CIN et la photo de votre équipement)
              </p>
            </div>
            <div className="div-arrow">
              <img
                className="img-arrow"
                src={`${process.env.PUBLIC_URL}/assets/images/arrow.svg`}
              />
            </div>
          </div>
          <div className="form3 bloc-sinistre-boostrap">
            <div className="bloc-marche">
              <div className="num-stepperrr">4</div>
              <p className="parg-marche">
                Téléchargez la notice d'information et prenez connaissance des
                conditions générales
              </p>
            </div>
            <div className="div-arrow">
              <img
                className="img-arrow"
                src={`${process.env.PUBLIC_URL}/assets/images/arrow.svg`}
              />
            </div>
          </div>
          <div className="form3 bloc-sinistre-boostrap">
            <div
              className="bloc-marche"
              style={{ background: "linear-gradient(#fd8cc4, #e31279)" }}
            >
              <p className="parg-marche" style={{ color: "#ffffff" }}>
                Payez et signez votre bulletin d'adhésion. Votre équipement est
                désormais protégé.
              </p>
            </div>
          </div>
        </div>
      </div> */}

      {/* fromulle blockkkkkkk
       <div
        className="row col-lg-12 col-md-12 formule-offer"
        style={{ marginTop: "5%" }}
      >
        <div
          className="row col-lg-12 col-md-12"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <div
            className="col-12 col-lg-12 col-md-12"
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <div className="col-md-12 text-center">
              <h2 className="simulez-prix">
                NOS FORMULES DE GARANTIES OPTIQUES
              </h2>
              <p className="col-8" style={{ marginBottom: "3%" }}>
                Nous vous proposons plusieurs formules de garanties selon le
                prix d’achat de vos lunettes. Découvrez les détails de chaque
                formule et assurez vos optiques pour une utilisation en toute
                sérénité !
              </p>
            </div>
            <div
              className="col-xl-12 col-lg-12 col-md-12"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div className="form1" style={{ paddingRight: "1%" }}>
                {formuleList.map((el, index) => (
                  <div
                    className={
                      keyFormula === index
                        ? "col-12 formula-select"
                        : "col-12 formula"
                    }
                    key={index}
                    onClick={() => {
                      setKeyFormula(index);
                    }}
                  >
                    <div
                      className="form"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <h3
                        className="dispnone"
                        style={
                          keyFormula === index
                            ? { color: "#ffffff", paddingLeft: "5px" }
                            : { color: "#888888", paddingLeft: "5px" }
                        }
                      >
                        Formule
                      </h3>
                      <img
                        className={
                          keyFormula === index
                            ? "img-formula-select"
                            : "img-formula"
                        }
                        src={
                          `${process.env.PUBLIC_URL}/assets/images/` +
                          el.fomula?.name +
                          ".svg"
                        }
                      />
                    </div>
                    <div className="col-2 dispnone"></div>
                    <div className="col-4 text-formula">
                      <p
                        style={
                          keyFormula === index
                            ? { color: "#ffffff" }
                            : { color: "#888888" }
                        }
                      >
                        {(el?.fomula?.price / 12)?.toFixed(3)} DT / mois
                      </p>
                      <p
                        style={
                          keyFormula === index
                            ? { color: "#ffffff" }
                            : { color: "#888888" }
                        }
                      >
                        {el?.fomula?.price?.toFixed(3)} DT / an
                      </p>
                    </div>
                  </div>
                ))}
              </div>

              <div className="form2" style={{ paddingLeft: "1%" }}>
                <div className="div-extend-formule" style={{ height: "580px" }}>
                  <h3 className="text-align">Formule</h3>
                  <img
                    className="img-formule-extend"
                    src={
                      `${process.env.PUBLIC_URL}/assets/images/` +
                      formuleList[keyFormula]?.fomula?.name +
                      ".svg"
                    }
                  />
                  <div className="d-flex">
                    <h2
                      style={{
                        fontSize: "23px",
                        fontWeight: "bold",
                        marginTop: "15%",
                        fontFamily: "Century Gothic",
                      }}
                    >
                      {formuleList[keyFormula]?.fomula?.price?.toFixed(3)} DT /
                      mois
                    </h2>
                    <p
                      style={{
                        fontWeight: "bold",
                        fontSize: "20px",
                        marginBottom: "6px",
                      }}
                    >
                      {formuleList[keyFormula]?.fomula?.price?.toFixed(3)} DT /
                      an
                    </p>
                    <p style={{ fontWeight: "bold", fontSize: "11px" }}>
                      prix des lunettes de{" "}
                      {formuleList[keyFormula]?.fomula?.minValueProd}
                      {formuleList[keyFormula]?.fomula?.maxValueProd >= 9998
                        ? " et +"
                        : `${-formuleList[keyFormula]?.fomula
                            ?.maxValueProd} DT`}
                    </p>
                    <div className="row col-md-12 detail-formule">
                      <p className="col-md-12" style={{ fontSize: "14px" }}>
                        {formuleList[keyFormula]?.fomula?.maxValueProd <= 500 &&
                        formuleList[keyFormula]?.fomula?.minValueProd <= 200
                          ? "Lunettes de vue"
                          : "Lunettes de vue et solaires"}
                      </p>
                    </div>
                    <div className="row col-md-12 detail-formule">
                      <p className="col-md-12" style={{ fontSize: "14px" }}>
                        {formuleList[keyFormula]?.fomula?.maxValueProd < 500 &&
                        formuleList[keyFormula]?.fomula?.minValueProd === 1
                          ? "4 paires du foyer couvertes"
                          : "1 paire couverte"}
                      </p>
                    </div>
                    <div className="row col-md-12 detail-formule">
                      <p className="col-md-10" style={{ fontSize: "12px" }}>
                        {formuleList[keyFormula]?.fomula?.nbrIntervYear > 1
                          ? "4 interventions /an"
                          : "1 intervention /an"}
                      </p>
                    </div>
                    {formuleList[keyFormula]?.listBreakDown?.map((br, ke) =>
                      br.value ? (
                        <>
                          <div
                            key={ke}
                            className="row col-md-12 detail-formule"
                          >
                            <p
                              className="col-md-10"
                              style={{ fontSize: "12px" }}
                            >
                              {br.name}
                            </p>
                          </div>
                        </>
                      ) : (
                        <></>
                      )
                    )}
                    <div className="row col-md-12 detail-formule">
                      <p className="col-md-12" style={{ fontSize: "13px" }}>
                        Assistance téléphonique 7j/7
                      </p>
                    </div>
                    <p
                      style={{
                        fontSize: "17px",
                        fontWeight: "bold",
                        marginTop: "5px",
                      }}
                    >
                      Plafond de garantie{" "}
                      {formuleList[keyFormula]?.fomula?.ceilingGuarantee} DT
                    </p>
                    <div className="row col-md-12 detail-formule">
                      <p className="col-md-12" style={{ fontSize: "14px" }}>
                        Dont verre {formuleList[keyFormula]?.fomula?.dontverre}{" "}
                        DT
                      </p>
                    </div>
                    <div className="row col-md-12 detail-formule">
                      <p className="col-md-12" style={{ fontSize: "14px" }}>
                        Dont montures{" "}
                        {formuleList[keyFormula]?.fomula?.dontmontures} DT
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      
      <div className="row col-md-12 d-flex-j-c" style={{ marginTop: "5%" }}>
        <div className="col-md-8">
          <h2 className="title-bloc-couvert">
            NOUS AVONS CHOISI LES MEILLEURES GARANTIES POUR VOUS
          </h2>
          <div
            className="row col-md-12 col-sm-12"
            style={{ marginRight: "0", marginTop: "50px" }}
          >
            <div className="col-md-6 col-sm-6 bloc-paragraphe-couvert">
              <img
                className="image-parahraphe-couvert1"
                src={`${process.env.PUBLIC_URL}/assets/images/Casse tte cause.svg`}
              />
              <div
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  flexDirection: "column",
                  paddingLeft: "30px",
                }}
              >
                <h3 className="title-parahraphe-couvert">
                  Casse toutes causes
                </h3>
                <p className="parahraphe-couvert">
                  Les frais de remplacement ou de réparations de vos optiques
                  présentent des coûts élevés, nous couvrons l’ensemble de votre
                  équipement optique, montures et verres, à partir de la date
                  d’achat de vos lunettes.
                </p>
              </div>
            </div>
            <div className="col-md-6 col-sm-6 bloc-paragraphe-couvert">
              <img
                className="image-parahraphe-couvert1"
                src={`${process.env.PUBLIC_URL}/assets/images/Broken optique.svg`}
              />
              <div
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  flexDirection: "column",
                  paddingLeft: "30px",
                }}
              >
                <h3 className="title-parahraphe-couvert">Casse Verre</h3>
                <p className="parahraphe-couvert">
                  La casse de vos verres peut se révéler particulièrement
                  incommodante et inconfortable. Avec une assurance optique,
                  finit les dépenses imprévues qui grèvent le budget
                </p>
              </div>
            </div>
            <div className="col-md-6 col-sm-6 bloc-paragraphe-couvert">
              <img
                className="image-parahraphe-couvert1"
                src={`${process.env.PUBLIC_URL}/assets/images/Casse Monture.svg`}
              />
              <div
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  flexDirection: "column",
                  paddingLeft: "30px",
                }}
              >
                <h3 className="title-parahraphe-couvert">Casse Monture</h3>
                <p className="parahraphe-couvert">
                  Si vos montures sont cassées par un tiers (par exemple un de
                  vos amis s'est assis dessus ou un camarade de classe de votre
                  enfant les a cassées), ne vous inquiétez pas Garanty prend en
                  chargent les frais de réparations ou de remplacements de vos
                  montures.
                </p>
              </div>
            </div>
            <div className="col-md-6 col-sm-6 bloc-paragraphe-couvert">
              <img
                className="image-parahraphe-couvert1"
                src={`${process.env.PUBLIC_URL}/assets/images/assistance.svg`}
              />
              <div
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  flexDirection: "column",
                  paddingLeft: "30px",
                }}
              >
                <h3 className="title-parahraphe-couvert">
                  Des avantages exclusifs
                </h3>
                <p className="parahraphe-couvert">
                  Garanty propose une gamme d’assurance optique complète et
                  évolutive. Elle couvre aussi bien vos montures que vos verres
                  correcteurs. Pour assurer les premières lunettes de vos
                  enfants où pour garantir vos montures, les différentes
                  formules d’assurance optique Garanty s’adaptent à vos besoins.
                </p>
              </div>
            </div>
            <div className="col-md-6 col-sm-6 bloc-paragraphe-couvert">
              <img
                className="image-parahraphe-couvert1"
                src={`${process.env.PUBLIC_URL}/assets/images/Offre el 3ayla.svg`}
              />
              <div
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  flexDirection: "column",
                  paddingLeft: "30px",
                }}
              >
                <h3 className="title-parahraphe-couvert">
                  Offre IFRAH W FARAH
                </h3>
                <p className="parahraphe-couvert">
                  Afin de vous apporter la meilleure garantie de service et
                  assurer au mieux toute la famille, pour seulement 138DT par
                  an, la formule IFRAH w FARAH, vous permet de couvrir 4 paires
                  de lunettes de vues, 2 paires adultes et 2 paires enfants.
                </p>
              </div>
            </div>
            <div className="col-md-6 col-sm-6 bloc-paragraphe-couvert">
              <img
                className="image-parahraphe-couvert1"
                src={`${process.env.PUBLIC_URL}/assets/images/Rembourssement.svg`}
              />
              <div
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  flexDirection: "column",
                  paddingLeft: "34px",
                }}
              >
                <h3 className="title-parahraphe-couvert">Économique</h3>
                <p className="parahraphe-couvert">
                  En cas de casse, nous prenons en charge les frais de
                  réparations ou de remplacement de vos lunettes optiques ou
                  solaires. Garant vous permet de réaliser des économies de dès
                  la première année.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="row col-md-12 d-flex-j-c margin0"
        style={{ backgroundColor: "rgb(12 1 95)" }}
      >
        <div className="col-md-8">
          <h2 className="title-bloc-couvert" style={{ color: "#e72283" }}>
            AVANTAGES DE GARANTY
          </h2>
          <div
            className="row col-md-12 col-sm-12"
            style={{
              marginRight: "0",
              marginTop: "50px",
              marginBottom: "50px",
            }}
          >
            <div className="col-md-4 col-sm-6">
              <h3 className="firsttitle100">100%</h3>
              <h3 className="lasttitle100">Adapté à vos besoins</h3>
              <p className="paragraphe-avantage">
                L'assurance personnalisable et adaptée qui vous protège contre
                les risques du quotidien
              </p>
            </div>
            <div className="col-md-4 col-sm-6">
              <h3 className="firsttitle100">100%</h3>
              <h3 className="lasttitle100">Digital</h3>
              <p className="paragraphe-avantage">
                Vous pouvez souscrire votre contrat totalement en ligne. Notre
                modèle est 100% digital et approuvé par des algorithmes et des
                équipes disponibles et a votre service.
              </p>
            </div>
            <div className="col-md-4 col-sm-6">
              <h3 className="firsttitle100">7J/7</h3>
              <h3 className="lasttitle100">
                Garanty , c'est aussi simple que ça
              </h3>
              <p className="paragraphe-avantage">
                Une couverture sur laquelle vous pouvez compter, de la
                protection de votre appareil à l'assistance de nos experts 6j/7.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        className="row col-md-12 sinistre-garanty"
        style={{ marginTop: "5%" }}
      >
        <div className="col-md-9 gr-container">
          <h2 className="text-1">
            EN CAS DE SINISTRE,
            <br />
            GARANTY A DÉJÀ TOUT PRÉVU
          </h2>
          <div
            className="row col-md-12 col-sm-12"
            style={{
              marginRight: "0",
              marginTop: "20px",
              marginBottom: "50px",
            }}
          >
            <div className="form4 bloc-sinistre-boostrap">
              <div className="bloc-sinistre">
                <h3 className="title-parahraphe-stepper">
                  Vous déclarez votre sinistre
                </h3>
                <p className="parahraphe-couvert1">
                  En ligne depuis votre espace client ou par téléphone au 71 169
                  000
                </p>
                <img
                  style={{ width: "115px" }}
                  src={`${process.env.PUBLIC_URL}/assets/images/1.png`}
                />
              </div>
            </div>
            <div className="form4 bloc-sinistre-boostrap">
              <div className="bloc-sinistre">
                <h3 className="title-parahraphe-stepper">
                  Garanty vous répond
                </h3>
                <p className="parahraphe-couvert1">
                  Vous recevrez une réponse sous 24h
                </p>
                <img
                  style={{ width: "115px" }}
                  src={`${process.env.PUBLIC_URL}/assets/images/2.png`}
                />
              </div>
            </div>
            <div className="form4 bloc-sinistre-boostrap">
              <div className="bloc-sinistre">
                <h3 className="title-parahraphe-stepper">
                  Nous récupérons votre équipement
                </h3>
                <p className="parahraphe-couvert1">
                  Nous vous envoyons un technicien sous 24h sans frais
                  supplémentaires
                </p>
                <img
                  style={{ width: "115px" }}
                  src={`${process.env.PUBLIC_URL}/assets/images/3.png`}
                />
              </div>
            </div>
            <div className="form4 bloc-sinistre-boostrap">
              <div className="bloc-sinistre">
                <h3 className="title-parahraphe-stepper">Mon équipement</h3>
                <p className="parahraphe-couvert1">
                  Est réparé, remplacé ou remboursé
                </p>
                <img
                  style={{ width: "115px" }}
                  src={`${process.env.PUBLIC_URL}/assets/images/4.png`}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row col-md-12 col-lg-12 col-sm-12 col-12 proteger-aimez">
        <div className="col-md-12 col-lg-12 col-sm-12 col-12 div-FAQ">
          <h2 className="text-1" style={{ margin: "0" }}>
            QUESTIONS FRÉQUENTES
          </h2>
        </div>
        {showData ? (
          <div className="card-box-4">
            <Accordion>
              <Fragment>
                {allData[1].list.map((subEl, i) => {
                  return (
                    <Accordion.Item
                      key={i}
                      className="faq-drop-item  "
                      eventKey={i.toString()}
                    >
                      <Accordion.Header>
                        <p className="faq-sub-title"> {subEl.title}</p>
                      </Accordion.Header>
                      <Accordion.Body className="faq-text">
                        {subEl.text}
                      </Accordion.Body>
                    </Accordion.Item>
                  );
                })}
              </Fragment>
            </Accordion>
          </div>
        ) : (
          <></>
        )}
        <div
          className="col-md-12 col-lg-12 col-sm-12 col-12 div-arrow-FAQ"
          onClick={() => {
            setShowData(!showData);
          }}
        >
          <img
            className={showData ? "img-arrow-faq-hide" : "img-arrow-faq-show"}
            src={`${process.env.PUBLIC_URL}/assets/images/arrow.svg`}
          />
        </div>
      </div>
      <div className="row col-md-12 col-lg-12 col-sm-12 col-12 newsletter d-flex-a">
        <div className="row col-md-12 d-flex-j">
          <div className="col-md-10 d-flex-j" style={{ marginTop: "30px" }}>
            <h2 className="text-2 ">Recevez notre Newsletter</h2>
          </div>
        </div>
        <div
          className="row col-md-8 col-lg-8 col-sm-8 col-8"
          style={{ marginBottom: "50px" }}
        >
          <div className="col-md-9">
            <input
              style={{ paddingLeft: "15px" }}
              className="newletter-input"
              name="email"
              type="text"
              placeholder="Votre adresse e-mail....."
              onChange={handelChange}
            />
          </div>
          <div className="col-md-3">
            <button
              className="newletter-btn"
              onClick={() => {
                if (!newsletter.email) {
                  toast.error("Entrez vous votre e-mail");
                } else {
                  adminService.addToNewsletter(newsletter).then((res) => {
                    if (res.status === 202) {
                      toast.success(
                        "Vous êtes enregistré dans notre newsletter."
                      );
                      history.push({ pathname: "/newsletter?envoyer=ok" });
                      window.scrollTo(500, 0);
                    } else if (res.status === 204) {
                      toast.error("Email déjà enregistré");
                    }
                  });
                }
              }}
            >
              s'abonner
            </button>
          </div>
        </div>
      </div>
      <Footer listFamily={familyList} />
      <Modal
        isOpen={Verticalcenter}
        toggle={Verticalcentermodaltoggle}
        centered
      >
        <ModalHeader
          toggle={Verticalcentermodaltoggle}
          style={{ flexDirection: "column" }}
        >
          <h6
            style={{
              font: "normal normal bold 25px/27px Century Gothic",
              color: "#0C015F",
            }}
          >
            Déclaration Sinistre
          </h6>
        </ModalHeader>
        <ModalBody>
          {/* <Col md="12 mb-4">
            <Label htmlFor="validationCustom01">Numéro CIN</Label>
            <Input
              className="form-control"
              name="cin"
              type="text"
              placeholder="Numéro CIN"
              onChange={handelChangeSi}
            />
          </Col> */}
          <Col md="12 mb-4">
            <Label htmlFor="validationCustom01">N° d'adhérent chez BHA</Label>
            <Input
              className="form-control"
              name="matricule"
              type="text"
              placeholder="N° d'adhérent chez BHA"
              onChange={handelChangeSi}
            />
          </Col>
          <Col md="12 mb-4">
            <Label htmlFor="validationCustom01">N° de contrat</Label>
            <Input
              className="form-control"
              name="seq_assu"
              type="text"
              placeholder="N° de contrat"
              onChange={handelChangeSi}
            />
          </Col>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={validPopup}>
            Continuer
          </Button>
        </ModalFooter>
      </Modal>
    </DocumentMeta>
  );
};

export default Optiquedetails;
