import React from "react";
import NavBarHome from "../home/NavBarHome";
import DocumentMeta from "react-document-meta";
import Footer from "../Footer/Footer";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
const DevenirPartenaire = () => {
  const history = useHistory();
  const family = useSelector(({ global }) => global.familiesList);
  const meta = {
    title: "Nos partenaires",
    description:
      "Garanty l'assurance 100% digitale en Tunisie pour Smartphone, Optique, Multimédia, Electroménager.  Choisissez la formule de garantie la plus adaptée à votre équipement",
    canonical: "http://example.com/path/to/page",
    meta: {
      charset: "utf-8",
      'http-equiv': {
        'cache-control': 'no-cache, must-revalidate, post-check=0, pre-check=0',
        expires: '0',
        pragma: 'no-cache',
      },
      name: {
        keywords: "react,meta,document,html,tags",
      },
    },
  };
  return (
    <DocumentMeta {...meta} className="container-fluid">
      <NavBarHome dark={true} />
      {/* <div className="row col-md-12 header-container-slidePartenaire"></div> */}
      <div className="row col-md-12">
        <h2 className="text-align title-dev-par">
          Devenez partenaire de Garanty
        </h2>
        <p className="text-align-P-dev">
          Augmentez vos revenus gràce à nos offres multiproduits et
          personnalisables !
        </p>
        <p
          className="text-align-P-dev"
          style={{ fontWeight: "bold", marginBottom: "2%" }}
        >
          Enrichissez votre expérience client avec des offres utiles
        </p>
        <div className="row col-md-12">
          <div className="row col-md-12">
            <div className="col-md-6" style={{ padding: "25px" }}>
              <div className="dev-par-card1">
                <img
                  style={{ maxWidth: "80%" }}
                  src={`${process.env.PUBLIC_URL}/assets/images/dev-par1.png`}
                />
                <p className="text-dev-card-arabe">
                  ضمّن على مراياتك و بالك مرتاح
                </p>
              </div>
            </div>
            <div className="col-md-6" style={{ padding: "25px" }}>
              <div className="dev-par-card3">
                <h3
                  style={{
                    color: "#e94475",
                    fontFamily: "Century Gothic",
                    fontWeight: "bold",
                    marginBottom: "10px",
                    textAlign: "center",
                  }}
                >
                  Pourquoi Garanty ?
                </h3>
                <p
                  className="text-align-P-dev"
                  style={{ fontWeight: "bold", marginTop: "1%" }}
                >
                  Le fournisseur le plus diversifié du canal d'assurance des
                  produits nomades
                </p>
                <p className="text-align-P-dev" style={{ marginTop: "1%" }}>
                  Garanty, c'est l'assurance qui protége tout ce que vos clients
                  aiment. Avec nous, pas de bla-bla inutile: ils savent ce
                  qu’ils payent et pourquoi.
                </p>
                <p className="text-align-P-dev">
                  Pratique,
                  <span
                    style={{ fontWeight: "bold", fontFamily: "Century Gothic" }}
                  >
                    100% digitale et personnalisable,
                  </span>{" "}
                  chez Garanty, le chef, c'est votre client. Le plus? vous
                  touchez une commission tous les mois, sur chaque contrat!
                </p>
                {/* <p className="text-align-P-dev" style={{ marginTop: "3%" }}>
                  Garanty est née d'une association entre
                </p>
                <p
                  className="text-align-P-dev"
                  style={{ marginTop: "3%", fontWeight: "bold" }}
                >
                  CARTE Assurance 
                </p>
                <p
                  className="text-align-P-dev"
                  style={{ marginTop: "1%", fontWeight: "bold" }}
                >
                  Gras Savoye Willis Tower Waston
                </p> */}
                <p className="text-align-P-dev" style={{ marginTop: "3%" }}>
                  Nous créons chaque produit en s'appuyant sur nos
                </p>
                <p className="text-align-P-dev">
                  partenaires pour vous offrir les meilleurs services
                </p>
              </div>
            </div>
          </div>
          <div className="row col-md-12" style={{ marginBottom: "3%" }}>
            <div className="col-md-6" style={{ padding: "25px" }}>
              <div className="dev-par-card3">
                <h3
                  className="text-align"
                  style={{
                    marginBottom: "12%",
                    color: "#e94475",
                    fontFamily: "Century Gothic",
                  }}
                >
                  Support client 7/7j <br />
                  au 71 169 000
                </h3>
                <div className="row col-md-12">
                  <div className="col-md-6" style={{ paddingBottom: "10%" }}>
                    <div className="mini-card-dev">
                      <div className="num-dev-par">1</div>
                      <p className="text-align-P-dev1">
                        Centre d’aide à la clientèle, des gestionnaires clients
                        à votre service
                      </p>
                    </div>
                  </div>
                  <div className="col-md-6" style={{ paddingBottom: "10%" }}>
                    <div className="mini-card-dev">
                      <div className="num-dev-par" style={{}}>
                        2
                      </div>
                      <p className="text-align-P-dev1">
                        Traitement de la demande en moins de 24 heures
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row col-md-12">
                  <div className="col-md-6" style={{ paddingBottom: "10%" }}>
                    <div className="mini-card-dev">
                      <div className="num-dev-par">3</div>
                      <p className="text-align-P-dev1">
                        Paiement du sinistre en moins de 14 jours ouvrables*
                        <br />
                        <span
                          className="text-align-P-dev"
                          style={{ fontSize: "15px" }}
                        >
                          *Après réception de tous les documents
                        </span>
                      </p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mini-card-dev">
                      <div className="num-dev-par">4</div>
                      <p className="text-align-P-dev1">
                        Espace client et gestion des dossiers en ligne
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6" style={{ padding: "25px" }}>
              <div className="dev-par-card2">
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/dev-par2.png`}
                  style={{ maxWidth: "80%" }}
                />
                <p className="text-dev-card-arabe">
                  La meilleure qualité de service <br />
                  est au coeur de nos priorités
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="row col-md-12"
        style={{
          backgroundColor: "rgb(39 62 146)",
          justifyContent: "center",
          display: "flex",
        }}
      >
        <h3
          className="text-align-P-dev"
          style={{ color: "white", marginTop: "2%", fontSize: "25px" }}
        >
          Fidélisez vos clients et accélérez votre croissance grace à des offres
          d'assurance <br /> simples, numériques et écoresponsables, conformes à
          la réglementation.
        </h3>
        <p
          className="text-align-P-dev"
          style={{
            marginTop: "2%",
            fontWeight: "bold",
            color: "#e60074",
            marginBottom: "10px",
          }}
        >
          Tous vos produits :
        </p>
        <p className="text-align-P-dev" style={{ color: "white" }}>
          Smartphone (neuf/usagés - particuliers/entreprises){" "}
        </p>
        <p className="text-align-P-dev" style={{ color: "white" }}>
          Appareil électroménager
        </p>
        {/* <p className="text-align-P-dev" style={{ color: "white" }}>
          Abonnements téléphoniques / forfaits mobiles
        </p> */}
        <p className="text-align-P-dev" style={{ color: "white" }}>
          Objets connectés
        </p>
        <p
          className="text-align-P-dev"
          style={{
            marginTop: "1%",
            fontWeight: "bold",
            color: "#e60074",
            marginBottom: "2%",
          }}
        >
          sont garanties contre :
        </p>
        <div className="garantie-contre">
          <div className="sous-garantie-contre">
            <div className="div-card-img-dev">
              <img
                style={{ width: "70%", height: "67%" }}
                src={`${process.env.PUBLIC_URL}/assets/images/Icon-Casse.png`}
              />
            </div>
            <p
              className="text-align-P-dev"
              style={{ color: "white", marginTop: "10%" }}
            >
              {" "}
              Casse
            </p>
          </div>
          <div className="sous-garantie-contre">
            <div className="div-card-img-dev">
              <img
                style={{ width: "78%" }}
                src={`${process.env.PUBLIC_URL}/assets/images/icons 4.svg`}
              />
            </div>
            <p
              className="text-align-P-dev"
              style={{ color: "white", marginTop: "10%" }}
            >
              Oxydation
            </p>
          </div>
          <div className="sous-garantie-contre">
            <div className="div-card-img-dev">
              <img
                style={{ width: "60%", height: "60%" }}
                src={`${process.env.PUBLIC_URL}/assets/images/Icon-Vol&Perte.png`}
              />
            </div>
            <p
              className="text-align-P-dev"
              style={{ color: "white", marginTop: "10%" }}
            >
              Casse accidentelle
            </p>
          </div>
          <div className="sous-garantie-contre">
            <div className="div-card-img-dev">
              <img
                style={{ width: "60%", height: "60%" }}
                src={`${process.env.PUBLIC_URL}/assets/images/icons 6.svg`}
              />
            </div>
            <p
              className="text-align-P-dev"
              style={{ color: "white", marginTop: "10%", whiteSpace: "nowrap" }}
            >
              Court-Circuit
            </p>
          </div>
        </div>
      </div>
      <div style={{ marginTop: "4%", marginBottom: "4%" }}>
        <p className="text-align-P-dev">
          Nous avons plusieurs produits à proposer à vos clients, mais une seule
          philosophie: celle de simplifier <br />
          l'assurance !
        </p>
        <p className="text-align-P-dev" style={{ fontWeight: "bold" }}>
          Vos clients peuvent choisir 1,2,3 ou meme 4 produits qui sont
          indispensables dans leur quotidien,
          <br /> personnalisables, pratique & économique
        </p>
        <div
          className="row col-md-12"
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "3%",
            marginBottom: "3%",
          }}
        >
          <div className="part-switch-prod-div">
            <div className="part-switch-prod">
              <div
                className="dev-par-card3"
                style={{ borderRadius: "0", alignItems: "center" }}
              >
                <img
                  style={{ maxWidth: "80%" }}
                  src={`${process.env.PUBLIC_URL}/assets/images/Smartphone.png`}
                />
              </div>
            </div>
            <div className="part-switch-prod">
              <div
                className="dev-par-card3"
                style={{ borderRadius: "0", alignItems: "center" }}
              >
                <img
                  style={{ maxWidth: "80%" }}
                  src={`${process.env.PUBLIC_URL}/assets/images/Optique.png`}
                />
              </div>
            </div>
            <div className="part-switch-prod">
              <div
                className="dev-par-card3"
                style={{ borderRadius: "0", alignItems: "center" }}
              >
                <img
                  style={{ maxWidth: "80%" }}
                  src={`${process.env.PUBLIC_URL}/assets/images/Électroménager.png`}
                />
              </div>
            </div>
            <div className="part-switch-prod">
              <div
                className="dev-par-card3"
                style={{ borderRadius: "0", alignItems: "center" }}
              >
                <img
                  style={{ maxWidth: "80%" }}
                  src={`${process.env.PUBLIC_URL}/assets/images/Multimédia.png`}
                />
              </div>
            </div>
          </div>
        </div>
        <p className="text-align-P-dev">
          Afin de trier parti des prestations intégrales, Garanty complète ses
          programmes d'assurance avec une <br />
          large gamme de prestations, dont,
          <span style={{ fontWeight: "bold", fontFamily: "Century Gothic" }}>
            la réparation, le remplacement, le recyclage et la seconde vie.
          </span>
        </p>
        <p className="text-align-P-dev">
          Seul acteur du marché de l'assurance affinitaire à proposer cette
          approche 360°, nous mettons à profit <br />
          notre expertise et notre savoir-faire.
        </p>
      </div>
      <div
        className="row col-md-12"
        style={{
          display: "flex",
          background: "linear-gradient(#e31279,#9F1C7A)",
          justifyContent: "center",
        }}
      >
        <div className="row col-md-12">
          <div>
            <p
              className="text-align"
              style={{
                color: "#ffffff",
                fontFamily: "Century Gothic",
                fontWeight: "bold",
                fontSize: "25px",
                marginTop: "40px",
                marginBottom: "40px",
              }}
            >
              Ils nous font déjà confiance
            </p>
          </div>
        </div>
        <div
          className="img-mult-covert"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            style={{ maxWidth: "50%" }}
            src={`${process.env.PUBLIC_URL}/assets/images/A13.png`}
          />
        </div>
        <div
          className="img-mult-covert"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            style={{ maxWidth: "50%" }}
            src={`${process.env.PUBLIC_URL}/assets/images/A8.png`}
          />
        </div>
        <div
          className="img-mult-covert"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            style={{ maxWidth: "50%" }}
            src={`${process.env.PUBLIC_URL}/assets/images/A20.png`}
          />
        </div>
        <div
          className="img-mult-covert"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            style={{ maxWidth: "50%" }}
            src={`${process.env.PUBLIC_URL}/assets/images/A18.png`}
          />
        </div>
        <div
          className="img-mult-covert"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            style={{ maxWidth: "50%" }}
            src={`${process.env.PUBLIC_URL}/assets/images/A5.png`}
          />
        </div>
        <div
          className="img-mult-covert"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            style={{ maxWidth: "50%" }}
            src={`${process.env.PUBLIC_URL}/assets/images/A2.png`}
          />
        </div>
        <div
          className="img-mult-covert"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            style={{ maxWidth: "50%" }}
            src={`${process.env.PUBLIC_URL}/assets/images/A14.png`}
          />
        </div>
        <div
          className="img-mult-covert"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            style={{ maxWidth: "50%" }}
            src={`${process.env.PUBLIC_URL}/assets/images/A7.png`}
          />
        </div>

        <div
          className="img-mult-covert"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            style={{ maxWidth: "50%" }}
            src={`${process.env.PUBLIC_URL}/assets/images/A14.png`}
          />
        </div>
        <div
          className="img-mult-covert"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            style={{ maxWidth: "50%" }}
            src={`${process.env.PUBLIC_URL}/assets/images/A15.png`}
          />
        </div>
        <div
          className="img-mult-covert"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            style={{ maxWidth: "50%" }}
            src={`${process.env.PUBLIC_URL}/assets/images/A1.png`}
          />
        </div>
        <div
          className="img-mult-covert"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            style={{ maxWidth: "30%" }}
            src={`${process.env.PUBLIC_URL}/assets/images/A22.png`}
          />
        </div>
        <div
          className="img-mult-covert"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            style={{ maxWidth: "50%" }}
            src={`${process.env.PUBLIC_URL}/assets/images/A11.png`}
          />
        </div>
        <div
          className="img-mult-covert"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            style={{ maxWidth: "50%" }}
            src={`${process.env.PUBLIC_URL}/assets/images/A16.png`}
          />
        </div>
        <div
          className="img-mult-covert"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            style={{ maxWidth: "40%" }}
            src={`${process.env.PUBLIC_URL}/assets/images/A19.png`}
          />
        </div>
        <div
          className="img-mult-covert"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            style={{ maxWidth: "50%" }}
            src={`${process.env.PUBLIC_URL}/assets/images/A3.png`}
          />
        </div>
        <div
          className="img-mult-covert"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            style={{ maxWidth: "60%" }}
            src={`${process.env.PUBLIC_URL}/assets/images/A21.png`}
          />
        </div>
        <div
          className="img-mult-covert"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            style={{ maxWidth: "60%" }}
            src={`${process.env.PUBLIC_URL}/assets/images/A6.png`}
          />
        </div>
        <div
          className="img-mult-covert"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            style={{ maxWidth: "50%" }}
            src={`${process.env.PUBLIC_URL}/assets/images/A12.png`}
          />
        </div>
        <div
          className="img-mult-covert"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            style={{ maxWidth: "50%" }}
            src={`${process.env.PUBLIC_URL}/assets/images/A4.png`}
          />
        </div>
      </div>
      <div style={{ height: "80px" }}></div>
      <div
        className="row col-md-12"
        style={{
          backgroundColor: "#232E85",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div className="status">
          <div className="sous-status">
            <div
              className="dev-par-card3"
              style={{
                border: "2px solid white",
                alignItems: "center",
                backgroundColor: "#232E85",
              }}
            >
              <p className="pp">
                <span style={{ fontSize: "35px" }}>+ 100</span> <br />
                partenaires
              </p>
            </div>
          </div>
          <div className="sous-status">
            <div
              className="dev-par-card3 "
              style={{
                border: "2px solid white",
                alignItems: "center",
                backgroundColor: "#232E85",
              }}
            >
              <p className="pp">
                <span style={{ fontSize: "35px" }}>+ 2000</span> <br />
                utilisateurs
              </p>
            </div>
          </div>
          <div className="sous-status">
            <div
              className="dev-par-card3"
              style={{
                border: "2px solid white",
                alignItems: "center",
                backgroundColor: "#232E85",
              }}
            >
              <p className="pp">
                <span style={{ fontSize: "35px" }}>+ 15</span> <br />
                Conseillers
              </p>
            </div>
          </div>
          <div className="sous-status">
            <div
              className="dev-par-card3"
              style={{
                border: "2px solid white",
                alignItems: "center",
                backgroundColor: "#232E85",
              }}
            >
              <p className="pp">
                joignable
                <br />
                <span style={{ fontSize: "35px" }}>7j/7</span>
              </p>
            </div>
          </div>
        </div>
              <div className="row col-sm-12 garantie-Smart1">
                <div className="col-sm-12">
                  <div className="btn-hold">
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <div
                        className="btn-decouvrezGarantie"
                        style={{ border: "1px solid #ffffff",background: "linear-gradient(#232E85, #232E85)" }}
                        onClick={() => {
                          history.push({ pathname: "/contact", state: family });
                        }}
                        id="decouvrezGarantie"
                      >
                        <p className="btn-garanty-smartphone">Contactez-Nous</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
      </div>
      <Footer />
    </DocumentMeta>
  );
};

export default DevenirPartenaire;
