import React from "react";
import GoogleMaps from "./GoogleMaps";
const NosPartenaires = () => {
  const API_KEY = "AIzaSyCWMMLLu1HB4VNrEZh1TZ4VDhQiHFU3iRk";
  return (
    <div style={{ marginTop: "10%" }}>
      {/* <GoogleMaps
        googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${API_KEY}`}
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={<div style={{ height: `400px` }} />}
        mapElement={<div style={{ height: `100%` }} />}
      /> */}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <img
          style={{ width: "50%", marginBottom: "40px" }}
          src={`${process.env.PUBLIC_URL}/assets/images/bh assu.png`}
        />
        {/* <img
          style={{ width: "45%", marginBottom: "15px"  }}
          src={`${process.env.PUBLIC_URL}/assets/images/Logo_gras_savoye.png`}
        /> */}
        <img
          style={{ width: "55%",}}
          src={`${process.env.PUBLIC_URL}/assets/images/wininti.png`}
        />
      </div>
    </div>
  );
};

export default NosPartenaires;
