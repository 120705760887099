import React from "react";
import * as FaIcons from "react-icons/fa";
import { Link } from "react-router-dom";
import "../Souscription.css";
import { useHistory } from "react-router";

function NavBar({ onClickMenu, text }) {
  const history = useHistory();
  const homeBtn = () => {
    history.push(`${process.env.PUBLIC_URL}`);
  };
  return (
    <div className="row col-lg-12 col-md-12 col-sm-12 col-12 header-souscription">
      {onClickMenu && (
        <div className="col-2 d-flex-a-c" style={{justifyContent:"flex-end"}}>
          <Link to="#" className="menu-bars">
            <FaIcons.FaBars color="white" onClick={onClickMenu} />
          </Link>
        </div>
      )}
      <div className="col-md-4 d-flex-a-c" style={{ paddingLeft: "30px",width: "79%", justifyContent:"flex-start" }}>
        <div
          className="logo-garanty d-flex-a-c"
          style={{ borderRight: "2px solid #FFFFFF" }}
        >
          <img
            style={{ width: "80%", cursor: "pointer" }}
            src={`${process.env.PUBLIC_URL}/assets/images/Logo Garanty Blanc.png`}
            onClick={homeBtn}
          />
        </div>
        <div
          className="logo-bh"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-end",
          }}
        >
          <img
            style={{ width: "80%" }}
            src={`${process.env.PUBLIC_URL}/assets/images/carte_assurance.svg`}
          />
        </div>
      </div>
      <div className="col-md-2 d-sous-a-c">
        <h6 style={{ color: "white", fontSize: "30px" }}>{text}</h6>
      </div>
      {/* <div className="col-md-3 d-flex-a-c">
        {localStorage.getItem("user") ? <div
          style={{ display: "flex", cursor: "pointer" }}
          onClick={() => {
            localStorage.clear();
            history.push(`${process.env.PUBLIC_URL}/home`);
          }}
        >
          <h6 style={{ fontSize: "19px", color: "#fff" }}>Déconnexion</h6>
          <img
            height="35"
            width="55"
            src={`${process.env.PUBLIC_URL}/assets/images/logout copy.svg`}
          />
        </div> : <></>}
      </div> */}
    </div>
  );
}

export default NavBar;
