import React, { useState } from "react";
import "../assets/styles/style.css";
import { GiCheckMark } from "react-icons/gi";
import { BsGoogle } from "react-icons/bs";
import { FaFacebookSquare } from "react-icons/fa";
import { useGoogleLogin } from "react-google-login";
import FacebookLogin from "react-facebook-login";
import { useHistory } from "react-router-dom";
import customerService from "../services/customer/customerService";
import Footer from "../components/Footer/Footer";
import DocumentMeta from "react-document-meta";
import baCheck from "../services/contract/baCheck.js";
function QrCode() {
  const history = useHistory();
  const [dataBa, setDataBa] = useState();
  const getContract = () => {
    const idContract = window.location.pathname.split("/")[2].split("=")[1];
    if (idContract) {
      baCheck.BAcheck(idContract).then((res) => {
        console.log(res.data);
        setDataBa(res.data);
      });
    }
  };
  React.useEffect(() => {
    getContract();
  }, [window.location.pathname, window.location.search]);

  window.gapi.load("client:auth2", () => {
    window.gapi.client.init({
      clientId:
        "602346386490-sbctad5pa4o07ppilp8a4i837eif6srd.apps.googleusercontent.com",
      plugin_name: "chat",
    });
  });
  const clientId =
    "602346386490-sbctad5pa4o07ppilp8a4i837eif6srd.apps.googleusercontent.com";
  const responseFacebook = (res) => {
    let name = res.name.split(" ");
    customerService
      .createFacebookCustomer({
        email: res.email,
        firstName: name[0],
        lastName: name[1],
        facebookid: res.userID,
      })
      .then((res) => {
        if (res.status == 200) {
          localStorage.setItem("user", JSON.stringify(res.data));
          history.push("/dashboard");
        } else if (res.status == 202) {
          localStorage.setItem("user", JSON.stringify(res.data));
          history.push("/dashboard");
        }
      });
  };

  const onSuccess = (res) => {
    customerService
      .createGoogleCustomer({
        email: res.profileObj.email,
        firstName: res.profileObj.givenName,
        lastName: res.profileObj.familyName,
        googleid: res.profileObj.googleId,
      })
      .then((res) => {
        if (res.status == 200) {
          localStorage.setItem("user", JSON.stringify(res.data));
          history.push("/dashboard");
        } else if (res.status == 202) {
          localStorage.setItem("user", JSON.stringify(res.data));
          history.push("/dashboard");
        }
      });
  };
  const onFailure = (res) => {
    console.log("login Failure:", res.details);
  };
  const { signIn } = useGoogleLogin({
    onSuccess,
    onFailure,
    clientId,
    accessType: "offline",
  });

  const seConnecter = async (e) => {
    e.preventDefault();
    history.push("/connexion");
  };
  return (
    <DocumentMeta>
      <div className="Center qrCode">
        <div className="bigContainer margin">
          <div className="spaceBetween margin">
            <div className="center">
              <img
                className="logoIcon"
                src={process.env.PUBLIC_URL + "/assets/images/bhassurance.png"}
                alt=""
              />
            </div>
            <div className="center">
              <img
                className="logoIcon"
                src={
                  process.env.PUBLIC_URL +
                  "/assets/images/Logo Garanty Bleu.png"
                }
                alt=""
              />{" "}
            </div>
            {/* <div>
              <img
                className="logoIcon"
                src={
                  process.env.PUBLIC_URL + "/assets/images/Logo_gras_savoye.png"
                }
                alt=""
              />
            </div> */}
          </div>
          <div className="Center textCenter margin Margin">
            <p className="century blod larger" style={{ color: "#393c8f" }}>
              Votre Bulletin d'adhesion est valid
            </p>
          </div>
          <div className="center margin Margin">
            <div
              className="smallContainer center margin "
              style={{ position: "relative" }}
            >
              <div className="absoluteCenter greenCircle center">
                <GiCheckMark color="white" size={30} />
              </div>
              <div className="margin ">
                <p className="century">Nom : {dataBa?.customer?.lastName}</p>
                <p className="century">Prénom : {dataBa?.customer?.firstName}</p>
                <p className="century">N°Cin /Passeport : {dataBa?.customer?.cin}</p>
                <p className="century">Formule d'assurance : {dataBa?.formula?.name}</p>
                <p className="century">
                  Validité : du {dataBa?.startDate} au {dataBa?.endDate}
                </p>
                <p className="century">Date d'achat : {dataBa?.dateCreation} </p>
              </div>
            </div>
          </div>
          <div
            className="row col-md-12 col-lg-12 col-sm-12"
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "2%",
            }}
          >
            <div
              className="col-sm-6 col-md-4 col-lg-6"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <div className="connect-btn">
                <button
                  className="connect-btn"
                  id="connecter"
                  onClick={seConnecter}
                >
                  {"SE CONNECTER"}
                </button>
              </div>
            </div>
            <h3
              style={{
                textAlign: "center",
                fontFamily: "Century Gothic",
                fontSize: "17px",
                marginTop: "18px",
                fontWeight: "bold",
              }}
            >
              Connectez-vous ou inscrivez-vous avec Google ou Facebook
            </h3>
            <div className="row col-md-6 col-lg-8 col-sm-8 socialmedia-div">
              <div
                className="col-sm-6 col-md-4 col-lg-4"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <div
                  className="connect-btn"
                  style={{
                    backgroundColor: "#ff2f19",
                    // border: "1px solid #ff2f19",
                    color: "white",
                    fontSize: "15px",
                    marginTop: "3px",
                  }}
                >
                  <button
                    className="connect-btn"
                    onClick={signIn}
                    style={{
                      backgroundColor: "#ff2f19",
                      // border: "1px solid #ff2f19",
                      color: "white",
                      fontSize: "15px",
                      justifyContent: "center",
                    }}
                  >
                    <BsGoogle size="30" style={{ marginRight: "14px" }} />
                    Google
                  </button>
                </div>
              </div>
              <div
                className="col-sm-6 col-md-4 col-lg-4"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <div
                  className="connect-btn"
                  style={{
                    backgroundColor: "#1e4799",
                    border: "1px solid #1e4799",
                    marginTop: "3px",
                  }}
                >
                  <button
                    className="connect-btn"
                    style={{
                      backgroundColor: "#1e4799",
                      border: "1px solid #1e4799",
                      fontSize: "15px",
                      justifyContent: "center",
                    }}
                  >
                    <FacebookLogin
                      appId="1512537785825577"
                      autoLoad={false}
                      fields="name,email,picture"
                      textButton={false}
                      cssClass="fb"
                      callback={responseFacebook}
                    />
                    <FaFacebookSquare size="30" className="fb2" />
                    Facebook
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </DocumentMeta>
  );
}

export default QrCode;
