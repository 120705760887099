import PropTypes from "prop-types";
import { Fragment, useState } from "react";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import Footer from "../Footer/Footer";
import { Accordion, Carousel } from "react-bootstrap";
import "./HomePageFooter.css";
import data from "./helpData.json";
import adminService from "../../services/adminService/adminService";

const HomePageFooter = ({ listFamily, text, element }) => {
  const history = useHistory();
  const [showData, setShowData] = useState(true);
  const [allData, setAllData] = useState(data);
  const [newsletter, setNewsletter] = useState({ email: "" });
  const handelChange = (e) => {
    const { name, value } = e.target;
    setNewsletter((newsletter) => ({ ...newsletter, [name]: value }));
  };
  let ind = element === undefined ? 0 : element >= 1 ? 1 : 0;
  return (
    <div className="container-fluide">
      <div className="row col-md-12 specialiste-ass">
        <h2 className="title-footer col-lg-9 col-md-10 col-sm-12 col-12">
          LA NOUVELLE ASSURANCE QUI CHANGE TOUT !
        </h2>
        <Carousel variant="dark" slide={true} className="footer-carossel">
          <Carousel.Item>
            <div className="row col-md-12">
              <div className="col-xl-12 col-lg-12 col-md-12 spec">
                <div
                  className="carro-div"
                  // style={{ paddingRight: "60px" }}
                >
                  <div>
                    <h3 className="title-footer1">Rapide</h3>
                    <div>
                      <p className="text-rapide">
                        Souscrivez à notre assurance ou déclarez un sinistre,
                        tout se fait en 3 clics.
                      </p>
                    </div>
                    <div>
                      <p className="text-rapide">
                        En toute simplicité et en toute transparence, on vous
                        prend en charge dans les 24H
                      </p>
                    </div>
                    <div>
                      <p className="text-rapide">
                        Accédez à votre espace client et profitez d’une
                        expérience unique.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-3 spec1">
                  <img
                    className="img-slide"
                    src={`${process.env.PUBLIC_URL}/assets/images/Smartphone 3.png`}
                  />
                </div>
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className="row col-md-12">
              <div className="col-xl-12 col-lg-12 col-md-12 spec">
                <div
                  className="carro-div"
                  // style={{ paddingRight: "60px" }}
                >
                  <div>
                    <h3 className="title-footer1">
                      On a tout prévu pour faire face à l’imprévu
                    </h3>
                    <div>
                      <p className="text-rapide">
                        Nous couvrons la technologie qui vous permet de rester
                        connecté
                      </p>
                    </div>
                    <div>
                      <p className="text-rapide">
                        La meilleure protection du marché: Casse, panne,
                        oxydation, dommage accidentel, Fini les dépenses
                        imprévues faites des économies
                      </p>
                    </div>
                    <div>
                      <p className="text-rapide">
                        La seule assurance optique, qui vous couvre en cas de
                        casse de vos lunettes
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-3 spec1">
                  <img
                    className="img-slide"
                    src={`${process.env.PUBLIC_URL}/assets/images/Smartphone 1.png`}
                  />
                </div>
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className="row col-md-12">
              <div className="col-xl-12 col-lg-12 col-md-12 spec">
                <div
                  className="carro-div"
                  // style={{ paddingRight: "60px" }}
                >
                  <div>
                    <h3 className="title-footer1">
                      Vivez l’instant présent et assurez-vous la tranquillité
                      d’esprit
                    </h3>
                    <div>
                      <p className="text-rapide">
                        {text === "Smartphone"
                          ? "Savez-vous que réparer un smartphone endommagé coûte jusqu’à 70% de sa valeur ?"
                          : "Savez-vous que réparer vos lunettes endommagées coûte jusqu’à 80% de leurs valeurs ?"}
                      </p>
                    </div>
                    <div>
                      <p className="text-rapide">
                        Garanty vous assure une couverture contre les dommages
                        accidentels de tous genres .
                      </p>
                    </div>
                    <div>
                      <p className="text-rapide">
                        En bref : on s'occupe de tout
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-3 spec1">
                  <img
                    className="img-slide"
                    src={`${process.env.PUBLIC_URL}/assets/images/Smartphone 2.png`}
                  />
                </div>
              </div>
            </div>
          </Carousel.Item>
        </Carousel>
      </div>
      <div className="row col-md-12 specialiste-ass">
        <h2
          className="title-footer col-lg-9 col-md-10 col-sm-12 col-12"
          style={{ marginBottom: "10px" }}
        >
          COMMENT ÇA MARCHE?
        </h2>
        <div
          className="row col-md-10 col-sm-10"
          style={{
            display: "flex",
            justifyContent: "center",
            marginRight: "0",
            marginTop: "50px",
            marginBottom: "50px",
          }}
        >
          <div className="form3 bloc-sinistre-boostrap">
            <div className="bloc-marche">
              <div className="num-stepperrr">1</div>

              <p className="parg-marche">
                Simulez le prix de votre garantie en indiquant le coût et la
                date d'achat de votre produit.
              </p>
            </div>
            <div className="div-arrow">
              <img
                className="img-arrow"
                src={`${process.env.PUBLIC_URL}/assets/images/arrow.svg`}
              />
            </div>
          </div>
          <div className="form3 bloc-sinistre-boostrap">
            <div className="bloc-marche">
              <div className="num-stepperrr">2</div>

              <p className="parg-marche">
                Créez votre compte Garanty en 2 minutes, complétez vos
                informations personnelles puis validez.
              </p>
            </div>
            <div className="div-arrow">
              <img
                className="img-arrow"
                src={`${process.env.PUBLIC_URL}/assets/images/arrow.svg`}
              />
            </div>
          </div>
          <div className="form3 bloc-sinistre-boostrap">
            <div className="bloc-marche">
              <div className="num-stepperrr">3</div>
              <p className="parg-marche">
                Souscrivez en indiquant les informations personnelles. Ajoutez
                les documents demandés (CIN et la photo de votre équipement)
              </p>
            </div>
            <div className="div-arrow">
              <img
                className="img-arrow"
                src={`${process.env.PUBLIC_URL}/assets/images/arrow.svg`}
              />
            </div>
          </div>
          <div className="form3 bloc-sinistre-boostrap">
            <div className="bloc-marche">
              <div className="num-stepperrr">4</div>
              <p className="parg-marche">
                Téléchargez la notice d'information et prenez connaissance des
                conditions générales
              </p>
            </div>
            <div className="div-arrow">
              <img
                className="img-arrow"
                src={`${process.env.PUBLIC_URL}/assets/images/arrow.svg`}
              />
            </div>
          </div>
          <div className="form3 bloc-sinistre-boostrap">
            <div
              className="bloc-marche"
              style={{ background: "linear-gradient(#fd8cc4, #e31279)" }}
            >
              <p className="parg-marche" style={{ color: "#ffffff" }}>
                Payez et signez votre bulletin d'adhésion. Votre équipement est
                désormais protégé.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        className="row col-sm-12 simulation-prix"
        style={{ marginBottom: "0" }}
      >
        <div className="col-12 col-md-6 col-lg-6 sim-text-div-right">
          <p className="text-sous-sim" style={{ marginTop: "10%" }}>
            Garanty votre assurance 100% digitale qui vous permet de souscrire
            un contrat en ligne et de choisir la formule de garantie la mieux
            adaptée à votre équipement.
          </p>
          <p className="text-sous-sim">
            En quelques clics et en toute sécurité, votre contrat est activé et
            votre équipement est protégé !
          </p>
          <p className="text-sous-sim">
            Estimez le tarif de votre souscription en fonction de votre modèle
            et découvrez la formule de garantie qui vous convient !
          </p>

          <p className="text-sous-sim" style={{ marginBottom: "10%" }}>
            En cas de sinistre, la déclaration est aussi en ligne sans frais
            supplémentaires, sans papiers et surtout sans stress ! Alors
            bienvenue au futur de l&#39;assurance
          </p>
        </div>
        <div className="col-12 col-md-6 col-lg-6 sim-text-div-left">
          <p className="text-sous-sim" style={{ marginTop: "10%" }}>
            <h2 className="title-sim-text">Garanty</h2>
            <h3 className="title-sim-text">
              Une assurance flexible et adaptable
            </h3>
            Vous pouvez à tout moment ajouter des biens à assurer ou changer de
            formule simplement via votre espace client. 
            <h3 className="title-sim-text">100% sécurisé</h3>
            Toutes vos transactions et vos paiements en ligne sont sécurisés.
            <h3 className="title-sim-text">Des partenaires solides</h3>
            Toutes nos garanties sont créées en partenariat avec des acteurs de confiance dans le secteur de
            l'assurance.
            <h3 className="title-sim-text">
              Une disponibilité de tout instant
            </h3>{" "}
            Nos équipes vous accompagnent et répondent à vos questions. Nous
            nous engageons à vous proposer un retour sous 24h.
          </p>
        </div>
      </div>
      <div className="row col-md-12 col-lg-12 col-sm-12 col-12 partenaires">
        <div className="col-md-10 col-lg-10 col-sm-10 col-10 gr-container">
          <div className="row col-md-12 col-lg-12 col-sm-12 col-12 d-flex-j margin0">
            <div className="col-md-10 col-lg-10 col-sm-11 col-11 padding20-40 d-flex-f">
              <h2 className=" text-font2" style={{ marginTop: "21px" }}>
                NOS PARTENAIRES: LE TOP DE L'ASSURANCE
              </h2>
              <div className="row col-md-12 col-md-6 col-lg-12 col-sm-12 col-12">
                <p className=" text-font2">Des partenaires (très) solides</p>
                <p className="color-font-sini">
                Garanty conçoit chaque produit avec le soutien
de partenaires reconnus dans l’industrie de
l'assurance.
                </p>
              </div>
              <div className="row col-md-12">
                <p className=" text-font2">Le tout, en bonne et due forme</p>
                <p className="color-font-sini">
                Tous les produits sont contrôlés et validés

par Carte Assurance.
                </p>
              </div>
              <div className="row col-md-12">
                <p className="text-font2">Une garantie de confiance...</p>
                <p className=" color-font-sini">
                  Pour vous assurer la tranquillité de l'esprit
                </p>
              </div>
              <div
                className="row col-md-12 col-lg-12 col-sm-12 col-12"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <div className="col-md-6 col-lg-6 col-sm-6 col-12 img-assurance-footer">
                  <img
                    className="max-widhth99"
                    src={`${process.env.PUBLIC_URL}/assets/images/carte_assurance.svg`}
                  />
                </div>
                {/* <div className="col-md-6 col-lg-6 col-sm-6 col-12 img-gras-footer">
                  <img
                    className="max-widhth99"
                    src={`${process.env.PUBLIC_URL}/assets/images/Gras-Savoye-wtw_logo.png`}
                  />
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row col-md-12 sinistre-garanty">
        <div className="col-md-9 gr-container">
          <h2 className="text-1" style={{ marginTop: "5%" }}>
          EN CAS DE SINISTRE,
            <br />
            GARANTY A DÉJÀ TOUT PRÉVU
          </h2>
          <div
            className="row col-md-12 col-sm-12"
            style={{
              marginRight: "0",
              marginTop: "20px",
              marginBottom: "50px",
            }}
          >
            <div className="form4 bloc-sinistre-boostrap">
              <div className="bloc-sinistre">
                <h3 className="title-parahraphe-stepper">
                  Vous déclarez votre sinistre
                </h3>
                <p className="parahraphe-couvert1">
                  En ligne depuis votre espace client ou par téléphone au 71 169
                  000
                </p>
                <img
                  style={{ width: "115px" }}
                  src={`${process.env.PUBLIC_URL}/assets/images/1.png`}
                />
              </div>
            </div>
            <div className="form4 bloc-sinistre-boostrap">
              <div className="bloc-sinistre">
                <h3 className="title-parahraphe-stepper">
                  Garanty vous répond
                </h3>
                <p className="parahraphe-couvert1">
                  Vous recevrez une réponse sous 24h
                </p>
                <img
                  style={{ width: "115px" }}
                  src={`${process.env.PUBLIC_URL}/assets/images/2.png`}
                />
              </div>
            </div>
            <div className="form4 bloc-sinistre-boostrap">
              <div className="bloc-sinistre">
                <h3 className="title-parahraphe-stepper">
                  Nous récupérons votre équipement
                </h3>
                <p className="parahraphe-couvert1">
                  Nous vous envoyons un technicien sous 24h sans frais
                  supplémentaires
                </p>
                <img
                  style={{ width: "115px" }}
                  src={`${process.env.PUBLIC_URL}/assets/images/3.png`}
                />
              </div>
            </div>
            <div className="form4 bloc-sinistre-boostrap">
              <div className="bloc-sinistre">
                <h3 className="title-parahraphe-stepper">Mon équipement</h3>
                <p className="parahraphe-couvert1">
                  Est réparé, remplacé ou remboursé
                </p>
                <img
                  style={{ width: "115px" }}
                  src={`${process.env.PUBLIC_URL}/assets/images/4.png`}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row col-md-12 col-lg-12 col-sm-12 col-12 proteger-aimez">
        <div className="col-md-8 div-proteger">
          <h2 className="text-1" style={{ marginBottom: "10%" }}>
            POUR PROTÉGER TOUT CE QUE VOUS AIMEZ
          </h2>
          <div className="div-img-proteger">
            {listFamily?.map((el, index) => (
              <img
                style={{ cursor: "pointer" }}
                key={index}
                onClick={() => {
                  if (el.familyName == "Smartphone") {
                    history.push({
                      pathname: "/garanties/smartphone",
                      state: el.idfamily,
                    });
                  } else if (el.familyName == "Optique") {
                    history.push({
                      pathname: "/garanties/optique",
                      state: el.idfamily,
                    });
                  } else {
                    toast.info("Prochainement disponible");
                  }
                }}
                src={
                  `${process.env.PUBLIC_URL}/assets/images/` +
                  el.familyName +
                  ".png"
                }
              />
            ))}
          </div>
          <div className="div-button-simu">
            <div className="col-md-5 d-flex-a">
              <button
                onClick={() => {
                  window.scrollTo(0, 850);
                }}
                className="simulation-btn"
              >
                Simulation Gratuite
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="row col-md-12 col-lg-12 col-sm-12 col-12 proteger-aimez">
        <div className="col-md-12 col-lg-12 col-sm-12 col-12 div-FAQ">
          <h2 className="text-1" style={{ margin: "0" }}>
            QUESTIONS FRÉQUENTES
          </h2>
        </div>
        {showData ? (
          <div className="card-box-4">
            <Accordion>
              <Fragment>
                {allData[ind].list.map((subEl, i) => {
                  return (
                    <Accordion.Item
                      key={i}
                      className="faq-drop-item  "
                      eventKey={i.toString()}
                    >
                      <Accordion.Header>
                        <p className="faq-sub-title"> {subEl.title}</p>
                      </Accordion.Header>
                      <Accordion.Body className="faq-text">
                        {subEl.text}
                      </Accordion.Body>
                    </Accordion.Item>
                  );
                })}
              </Fragment>
            </Accordion>
          </div>
        ) : (
          <></>
        )}
        <div
          className="col-md-12 col-lg-12 col-sm-12 col-12 div-arrow-FAQ"
          onClick={() => {
            setShowData(!showData);
          }}
        >
          <img
            className={showData ? "img-arrow-faq-hide" : "img-arrow-faq-show"}
            src={`${process.env.PUBLIC_URL}/assets/images/arrow.svg`}
          />
        </div>
      </div>
      <div className="row col-md-12 d-flex-j">
        <h2 className="text-1" style={{ marginBottom: "2%" }}>
          RECEVEZ NOTRE NEWSLETTER
        </h2>
      </div>
      <div
        className="row col-md-12 col-lg-12 col-sm-12 col-12 newsletter d-flex-a"
        style={{ height: "140px" }}
      >
        <div
          className="row col-md-7 col-lg-7 col-sm-7 col-7"
          style={{ paddingBottom: "15px", paddingTop: "15px" }}
        >
          <div className="col-md-9">
            <input
              style={{ paddingLeft: "15px" }}
              className="newletter-input"
              name="email"
              type="text"
              placeholder="Votre adresse e-mail....."
              onChange={handelChange}
            />
          </div>
          <div className="col-md-3">
            <button
              id="s'abonner"
              className="newletter-btn"
              onClick={() => {
                if (!newsletter.email) {
                  toast.error("Entrez vous votre e-mail");
                } else {
                  adminService.addToNewsletter(newsletter).then((res) => {
                    if (res.status === 202) {
                      toast.success(
                        "Vous êtes enregistré dans notre newsletter."
                      );
                      history.push({ pathname: "/newsletter?envoyer=ok" });
                      window.scrollTo(500, 0);
                    } else if (res.status === 204) {
                      toast.error("Email déjà enregistré");
                    }
                  });
                }
              }}
            >
              s'abonner
            </button>
          </div>
        </div>
      </div>
      <Footer listFamily={listFamily} />
      <div
        className="row col-12 newsletter d-flex-a"
        style={{ height: "30px" }}
      ></div>
    </div>
  );
};

export default HomePageFooter;
