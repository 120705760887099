import React, { useState } from "react";
import { useHistory } from "react-router";
import NavBar from "./Components/NavBar";
import { ParsedUrlQuery } from "querystring";
import contractService from "../../services/contract/contractService";
import adminService from "../../services/adminService/adminService";
import NumberFormat from "react-number-format";
import BeatLoader from "react-spinners/BeatLoader";
import { css } from "@emotion/react";
import { useSelector } from "react-redux";

function NewsletterMembership({ location }) {
  const queryParams = new URLSearchParams(window.location.search);
  const detailPhone = useSelector(
    ({ reducerSubscription }) => reducerSubscription.step0
  );
  //console.log(detailPhone);
  const [mobileInfo, setMobileInfo] = useState({});
  const [dataInfo, setDataInfo] = useState({});
  const [loading, setLoading] = useState(false);
  const [color, setColor] = useState("#000000");
  const override = css`
    position: absolute;
    top: 50%;
    left: 50%;
  `;
  const getAllData = async () => {
    const orderId = window.location.search.split("&")[0].split("=")[1];
    const idInfo = window.location.pathname.split("/")[2];
    await contractService.validatePayment(orderId);
    if (idInfo) {
      let objMobile = {};
      const res = await adminService.getInfoBa(idInfo);
      //console.log(res);
      for (const el of res.data) {
        objMobile[el.name] = el.value;
      }
      setMobileInfo(objMobile);
      const resData = await contractService.getContractbyId(idInfo);
      //console.log(resData);
      setDataInfo(resData.data);
    }
  };
  React.useEffect(() => {
    getAllData();
  }, [window.location.pathname, window.location.search]);

  const onSubmit = async () => {
    setLoading(!loading);
    const id = window.location.pathname.split("/")[2];
    await contractService.generateContratBase64(id);
    contractService
      .sign({
        failUrl: `${window.location.origin}/404`,
        id_contract: id,
        successUrl: `${window.location.origin}/dashboard/contract`,
      })
      .then((res) => {
        window.open(res.data, "_self");
      });
  };
  return (
    <div className="row  margin0 d-flex-j-c" style={{ position: "relative" }}>
      <NavBar />
      <div
        style={{
          height: "150px",
          width: "100%",
          backgroundColor: "#F3EBFF",
        }}
      ></div>
      {/* box */}
      <div
        className="card-box "
        style={{
          flexDirection: "column",
          width: "80%",
          height: "auto",
          position: "relative",
          top: "-80px",
          backgroundColor: "white",
          padding: "100px",
        }}
      >
        <div className="w-100 d-flex-j-c mb-5">
          <span
            className="d-flex-j-c title-member "
            style={{ flex: "1", height: "53px", alignItems: "center" }}
          >
            Bulletin d'adhésion
          </span>

          <span
            className="d-flex-j-c dot-value"
            style={{
              width: "148px",
              height: "53px",
              backgroundColor: "#F4F2FF",
              borderRadius: "10px",
              alignItems: "center",
            }}
          >
            N° {dataInfo.num}
          </span>
        </div>
        <p
          style={{
            font: "normal normal normal 10px/12px Century Gothic",
            marginBottom: "40px",
          }}
        >
          Contrat d’assurance collective à adhésion facultative N° 2022.406.000002 régi par le Code des Assurances promulgué par la loi N° : 92-124 du 09 03 1992 ainsi que les
                conditions générales « Assurance Multirisques Informatique » sous MF N° 365/7 du 18/12/1997 et les conditions particulières qui suivent, souscrit par la Société
                Tunisienne des Réparations et des Garanties (STRG), sise Tour Narcisse, Avenue du Japon, 1073 Montplaisir (RNE n° 1681780K), auprès de la société CARTE
                ASSURANCE, . au capital de 25 000 000 dinars sise Lot BC4 – Centre Urbain Nord Tunis Nord Tunis, 1003 Tunis, avec comme gestionnaire de services la société
                GARANTY dont les coordonnées figurent sur le Bulletin d’Adhésion.
        </p>
        <BandTitle text={"ADHERANT N°" + dataInfo.num} />
        <div className="row row-member">
          <div className="col-5">
            <p className="label-member">Produit d'assurance :</p>
            <p className="label-member">Nom :</p>
            <p className="label-member">Prénom :</p>
            <p className="label-member">Adresse :</p>
            <p className="label-member">Code postal :</p>
            <p className="label-member">Ville :</p>
            <p className="label-member">Tél 1 :</p>
            <p className="label-member">E-mail :</p>
          </div>
          <div className="col-7">
            <p className="value-member">
              {dataInfo?.formula?.family?.familyName}
            </p>
            <p className="value-member">{dataInfo?.customer?.lastName}</p>
            <p className="value-member">{dataInfo?.customer?.firstName}</p>
            <p className="value-member">{dataInfo?.customer?.adress}</p>
            <p className="value-member">{dataInfo?.customer?.postalCode}</p>
            <p className="value-member">{dataInfo?.customer?.city}</p>
            <p className="value-member">{dataInfo?.customer?.phone}</p>
            <p className="value-member">{dataInfo?.customer?.email}</p>
          </div>
        </div>
        <BandTitle text="CONDITION D'ASSURANCE" />
        <div className="row row-member">
          <div className="col-5">
            <p className="label-member">Date d'adhésion : </p>
            <p className="label-member">Date d'effet de la couverture :</p>
            <p className="label-member"> Date de fin (ou renouvellement) :</p>
            <p className="label-member"> Formule de garantie :</p>
            <p className="label-member">Plafond de garantie :</p>
            <p className="label-member">Moyen de paiement :</p>
            <p className="label-member">Cotisation (annuelle) :</p>
          </div>
          <div className="col-7">
            <p className="value-member">
              {new Date(dataInfo?.startDate).toLocaleDateString()}
            </p>
            <p className="value-member">
              {new Date(dataInfo?.startDate).toLocaleDateString()}
            </p>
            <p className="value-member">
              {new Date(dataInfo?.endDate).toLocaleDateString()}
            </p>
            <p className="value-member">{dataInfo?.formula?.name}</p>
            <p className="value-member">
              {dataInfo?.formula?.ceilingGuarantee}
            </p>
            <p className="value-member">{dataInfo?.paymentType?.name}</p>
            {
              <NumberFormat
                className="value-member"
                style={{ marginLeft: "1px" }}
                value={dataInfo?.totalAmount}
                displayType={"text"}
                thousandSeparator={true}
              />
            }
          </div>
        </div>
        <BandTitle text="CARACTERISTIQUES DE PRODUIT ASSURE" />
        <div className="row row-member mb-5">
          <div className="col-5">
            <p className="label-member">Type du Produit :</p>
            <p className="label-member"> Marque :</p>
            <p className="label-member">Modèle :</p>
            <p className="label-member">Valeur d'achat TTC :</p>
            <p className="label-member">N° de série (ou IMEI) :</p>
          </div>
          <div className="col-7">
            <p className="value-member">
              {dataInfo?.formula?.family?.familyName}
            </p>
            <p className="value-member"> {mobileInfo["Marque"]}</p>
            <p className="value-member">{mobileInfo["Modele"]}</p>
            <p className="value-member">{localStorage.getItem("amount")}dt</p>
            <p className="value-member"> {mobileInfo["Numero serie"]}</p>
          </div>
        </div>
        {loading ? (
          <div
            className="d-flex-j-c"
            style={{
              width: "397px",
              height: "138px",
              border: "1px dashed #707070",
              margin: "auto",
              alignItems: "center",
            }}
          >
            <BeatLoader
              color={color}
              loading={loading}
              // css={override}
              speedMultiplier={0.75}
              size={50}
            />
          </div>
        ) : (
          <div
            className="d-flex-j-c"
            style={{
              width: "397px",
              height: "138px",
              border: "1px dashed #707070",
              margin: "auto",
              alignItems: "center",
            }}
          >
            <button
              onClick={onSubmit}
              style={{ width: "268px", height: "73px" }}
              type="button"
              className="btn btn-valid-w"
            >
              Signer
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default NewsletterMembership;

function BandTitle({ text }) {
  return (
    <div
      style={{
        paddingLeft: "22px",
        font: " normal normal bold 16px/25px Century Gothic",
        height: "46px",
        width: "100%",
        borderRadius: "10px",
        backgroundColor: "#F4F2FF",
        display: "flex",
        alignItems: "center",
        marginBottom: "30px",
        marginTop: "30px",
      }}
    >
      {text}
    </div>
  );
}
